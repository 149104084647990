import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { List as IList } from 'immutable';
import { Container } from 'cstar-react-primitives/lib/redux-form/layout/Container';
import { getCapiSessionID } from 'capi/redux/immutable';
import { loadTrackingImagePixel } from '../validate/isProfane';
import {
    brandCatalogIsLoaded,
    getProfanityDetectionEnabled,
    getSelfBuyEnabled,
    getBrandCode
} from '../brand/brandSelectors';
import { primitiveMessages } from '../primitive/primitiveMessages';

import recipientMessages from './recipientMessages';
import RecipientNameForm from './RecipientNameForm';
import { shouldDisplayFaceplate } from './recipientStepSelectors';
import { newItemIsPlastic, newItemIsSelfBuy } from '../item/newItemForm';
import { useFlow } from '../routing/FlowProvider';
import {
    hasDigital as hasDigitalFaceplates,
    hasPlastic as hasPlasticFaceplates
} from '../faceplate/faceplateSelectors';
import LoadingIndicator from '../primitive/LoadingIndicator';
import { getPreferredRecipientName, getPreferredSelfBuy, getPreferredSenderName } from '../routing/flowSelectors';
import TranslatedPageTitle from '../header/TranslatedPageTitle';

export const RecipientStep = ({
    intl,
    stepName
}) => {
    const { goToNextStep, getSubmitButtonText } = useFlow();
    const [recipientSubmitButtonText, setRecipientSubmitButtonText] = useState(getSubmitButtonText(stepName));
    
    const isSelfBuyEnabled = useSelector(state => getSelfBuyEnabled(state));
    const isSelfBuy = useSelector(state => newItemIsSelfBuy(state));
    const isPlastic = useSelector(state => newItemIsPlastic(state));
    const { faceplate, showFaceplate, faceplates = IList() } = useSelector(state => shouldDisplayFaceplate(state));
    const catalogLoaded = useSelector(state => brandCatalogIsLoaded(state));
    const hasDigital = useSelector(state => hasDigitalFaceplates(state));
    const hasPlastic = useSelector(state => hasPlasticFaceplates(state));
    const isProfanityDetectionEnabled = useSelector(state => getProfanityDetectionEnabled(state));
    const capiSessionID = useSelector(state => getCapiSessionID(state));

    
    const preferredSelfBuy = useSelector(state => getPreferredSelfBuy(state));
    const preferredRecipientName = useSelector(state => getPreferredRecipientName(state));
    const preferrredSenderName = useSelector(state => getPreferredSenderName(state));
    const canSkip = preferredSelfBuy || (preferredSelfBuy === false && preferredRecipientName && preferrredSenderName);
    const isReady = catalogLoaded && (hasDigital || hasPlastic);

    const recipientTitle = faceplates.size > 1 ? recipientMessages.pluralTitle : recipientMessages.title;

    
    const [profanityFlag, setProfanityFlag] = useState(null);
    useEffect(() => {
        loadTrackingImagePixel(profanityFlag, capiSessionID);
    }, [profanityFlag]);

    useEffect(() => {
        if (isReady) {
            setRecipientSubmitButtonText(getSubmitButtonText(stepName, { isPlastic, isSelfBuy }));
            if (canSkip) {
                goToNextStep(stepName, { shouldPushHistory: false });
            }
        }
    }, [isReady, isSelfBuy]);

    const containerProps = {
        heading: intl.formatMessage(recipientTitle),
        HeadingElement: 'h1',
        showRequiredMsg: true,
        requiredMsg: intl.formatMessage(primitiveMessages.requiredInstructionText)
    };
    const formProps = {
        submitAction: (values) => {
            goToNextStep(stepName, {}, values);
        },
        cashbotName: 'recipient',
        isSelfBuyEnabled,
        isSelfBuy,
        faceplate,
        faceplates,
        showFaceplate,
        isProfanityDetectionEnabled,
        profanityFlag,
        setProfanityFlag,
        capiSessionID,
        submitButtonMessage: recipientSubmitButtonText
    };

    const printInfoBrands = ['OLDNAVY'];
    const brandCode = useSelector(state => getBrandCode(state));

    if (!isReady) {
        return (
            <React.Fragment>
                <LoadingIndicator />
            </React.Fragment>
        );
    }

    return (
        <Container {...containerProps}>
            <TranslatedPageTitle pageTitle={recipientTitle} />
            {printInfoBrands.includes(brandCode) && (
                <div className="print-info-div">
                    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.48461 6.15385V3.76923H14.6923H15.3846V1.53846C15.3846 0.684615 14.6923 0 13.8462 0H1.53846C0.684615 0 0 0.684615 0 1.53846V10.7692C0 11.6231 0.692308 12.3077 1.53846 12.3077H6.15385V10.7692H1.53846V6.15385H8.48461ZM1.53846 1.53846H13.8462V3.07692H1.53846V1.53846Z" fill="#1E1E1E" />
                        <path d="M18.1538 8.37597C19.1753 8.37597 20 9.17768 20 10.1708V13.7606H17.5384V16.1538H10.1538V13.7606H7.69226V10.1708C7.69226 9.17768 8.51688 8.37597 9.53841 8.37597H10.1538V5.38452H17.5384V8.37597H18.1538ZM11.3846 6.5811V8.37597H16.3076V6.5811H11.3846ZM16.3076 14.9572V12.564H11.3846V14.9572H16.3076ZM17.5384 12.564H18.7692V10.1708C18.7692 9.84179 18.4923 9.57256 18.1538 9.57256H9.53841C9.19995 9.57256 8.92303 9.84179 8.92303 10.1708V12.564H10.1538V11.3674H17.5384V12.564ZM18.1538 10.47C18.1538 10.7991 17.8769 11.0683 17.5384 11.0683C17.2 11.0683 16.923 10.7991 16.923 10.47C16.923 10.1409 17.2 9.8717 17.5384 9.8717C17.8769 9.8717 18.1538 10.1409 18.1538 10.47Z" fill="#1E1E1E" />
                    </svg>

                    <span>
                        <u>{intl.formatMessage(recipientMessages.printInfoTextUnderline)}</u>
                        &nbsp;
                        {intl.formatMessage(recipientMessages.printInfoText)}
                    </span>
                </div>
            )}
            <RecipientNameForm {...formProps} />
        </Container>
    );
};

RecipientStep.displayName = 'RecipientStep';

RecipientStep.propTypes = {
    stepName: PropTypes.string.isRequired,
    
    intl: intlShape.isRequired
};

export default injectIntl(RecipientStep);
