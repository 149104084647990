import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import form from 'redux-form/lib/Form';
import PropTypes from 'prop-types';
import { getCapiSessionID } from 'capi/redux/immutable';
import PhysicalAddress from './PhysicalAddress';
import OptionsSection from './OptionsSection';
import RecipientDetails from './RecipientDetails';
import { useFlow } from '../routing/FlowProvider';
import {
    brandCatalogIsLoaded,
    getBrandCode, getProfanityDetectionEnabled,
    getRequireRecipientEmailForPhysicalGift
} from '../brand/brandSelectors';
import {
    giftCardFieldNames,
    newItemCountry,
    newItemHasRecipientPlasticNotification, newItemIsPlastic,
    newItemRecipientName,
    newItemReduxForm
} from '../item/newItemForm';
import { ADDRESS_CONTEXT } from '../addressFields/addressFieldsModule';
import { changeValue } from '../item/newItemFormModule';
import { submitItem } from '../bundles/bundlesUtils';
import { getActiveProgramCatalogs, getActiveProgramCode } from '../program/programSelectors';
import { getDefaultFaceplateForProgram } from '../program/programListSelectors';
import { parseCartViewed } from '../segment/segmentSelectors';
import deliveryMessages from '../delivery/deliveryMessages';
import FormButton from '../primitive/buttons/FormButton';
import cartMessages from '../cart/cartMessages';
import { getShippingMethods } from '../shipping/shippingModule';
import LoadingIndicator from '../primitive/LoadingIndicator';
import { hasFaceplates } from '../faceplate/faceplateSelectors';
import { toggleStorefrontFlow } from '../routing/flowProgressModule';
import { warn } from '../message/validate';
import validate from './validate';


const ProductDetail = ({
    intl, addressContext, countryCode, handleSubmit,
    stepName, activeProgram,
    activeCatalogs,
    cartViewedSegment,
    getFaceplates,
    brandCode, hasPlasticNotification, requireRecipientEmailForPhysicalGift, recipientName,
    anyTouched, invalid, submitFailed, getSubmitMessage
}) => {
    const dispatch = useDispatch();
    const isPlastic = useSelector(state => newItemIsPlastic(state));

    const { goToNextStep } = useFlow();

    const submitAction = (submitValues) => {
        const plastic = submitValues.get('isPlastic');
        const finalselfbuy = submitValues.get('isSelfBuy');
        if (finalselfbuy) {
            dispatch(changeValue(giftCardFieldNames.IS_SHARE_VIA_LINK, false));
            dispatch(changeValue(giftCardFieldNames.EMAIL_FIELD_SELECTED, true));
        }
        
        dispatch(toggleStorefrontFlow(true));

        if (!plastic) {
            submitItem(
                dispatch,
                submitValues,
                activeProgram,
                activeCatalogs,
                cartViewedSegment,
                getFaceplates,
                brandCode
            );
        }
        if (plastic) {
            dispatch(changeValue(giftCardFieldNames.IS_SHARE_VIA_LINK, false));
            dispatch(changeValue(giftCardFieldNames.EMAIL_FIELD_SELECTED, false));
            const mergedValues = submitValues.merge({
                hasAccessory: !!submitValues.get(giftCardFieldNames.ITEM_ACCESSORY, false)
            });
            dispatch(getShippingMethods(mergedValues, true));
        }

        let newSubmitValues = submitValues;
        if (!newSubmitValues.has('message')) {
            
            newSubmitValues = newSubmitValues.set('message', null);
        }
        
        newSubmitValues = newSubmitValues.set('programCode', null);
        goToNextStep(stepName, {}, newSubmitValues);
    };

    const addressFieldProps = {
        addressContext,
        countryCode,
        cashbotName: 'delivery',
        doFocus: false,
        form,
        useAddressAutocomplete: true
    };

    const recipientNotificationProps = {
        hasPlasticNotification,
        requireRecipientEmailForPhysicalGift,
        cashbotName: 'delivery',
        recipientName,
        isPlastic: true,
        intl
    };

    const buttonProps = {
        btnText: intl.formatMessage(getSubmitMessage()),
        anyTouched,
        invalid,
        submitFailed,
        cashbotName: 'delivery',
        formName: form
    };

    const pageHeading = deliveryMessages.headerPlastic;
    const containerProps = {
        heading: intl.formatMessage(pageHeading),
        HeadingElement: 'h1'
    };

    const isReady = useSelector(state => hasFaceplates(state) && brandCatalogIsLoaded(state));
    if (!isReady) {
        return <LoadingIndicator />;
    }

    return (
        <div className="product-detail-container">
            <form method="POST" onSubmit={handleSubmit(submitAction)} noValidate>
                <OptionsSection
                    isPlastic={isPlastic}
                />
                <RecipientDetails
                    isPlastic={isPlastic}
                />
                {isPlastic && (
                    <PhysicalAddress
                        containerProps={containerProps}
                        addressFieldProps={addressFieldProps}
                        recipientNotificationProps={recipientNotificationProps}
                    />
                )}
                <FormButton {...buttonProps} />
            </form>
        </div>
    );
};

ProductDetail.defaultProps = {
    handleSubmit: () => { },
    addressContext: ADDRESS_CONTEXT.SHIPPING,
    hasPlasticNotification: false,
    anyTouched: false,
    invalid: false,
    submitFailed: false,
    getSubmitMessage: () => cartMessages.submitButtonText,
    countryCode: '',
    stepName: '',
    activeProgram: '',
    activeCatalogs: [],
    cartViewedSegment: false,
    getFaceplates: () => { },
    brandCode: '',
    requireRecipientEmailForPhysicalGift: '',
    recipientName: ''
};


ProductDetail.propTypes = {
    intl: intlShape.isRequired,
    addressContext: PropTypes.string,
    countryCode: PropTypes.string,
    handleSubmit: PropTypes.func,
    stepName: PropTypes.string,
    activeProgram: PropTypes.string,
    activeCatalogs: PropTypes.array,
    cartViewedSegment: PropTypes.bool,
    getFaceplates: PropTypes.func,
    brandCode: PropTypes.string,
    hasPlasticNotification: PropTypes.bool,
    requireRecipientEmailForPhysicalGift: PropTypes.string,
    recipientName: PropTypes.string,
    anyTouched: PropTypes.bool,
    invalid: PropTypes.bool,
    submitFailed: PropTypes.bool,
    getSubmitMessage: PropTypes.func,
    isProfanityDetectionEnabled: PropTypes.bool.isRequired,
    capiSessionID: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    recipientName: newItemRecipientName(state),
    hasPlasticNotification: newItemHasRecipientPlasticNotification(state),
    countryCode: newItemCountry(state),
    requireRecipientEmailForPhysicalGift: getRequireRecipientEmailForPhysicalGift(state),
    activeCatalogs: getActiveProgramCatalogs(state),
    activeProgram: getActiveProgramCode(state),
    getFaceplates: getDefaultFaceplateForProgram(state),
    brandCode: getBrandCode(state),
    cartViewedSegment: parseCartViewed(state),
    isProfanityDetectionEnabled: getProfanityDetectionEnabled(state),
    capiSessionID: getCapiSessionID(state)
});

export default connect(mapStateToProps)(injectIntl(newItemReduxForm(ProductDetail, { validate, warn })));
