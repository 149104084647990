import recipeientValidation from './RecipientValidation';
import physicalValidation from '../delivery/physicalValidation';

const validate = (values, props) => {
    const recipeintValidatrionResult = recipeientValidation(values, props);
    const isPlastic = values.get('isPlastic');
    let physicalValidationResult = {};
    if (isPlastic) {
        physicalValidationResult = physicalValidation(values, props);
    }
    return { ...recipeintValidatrionResult, ...physicalValidationResult };
};

export default validate;
