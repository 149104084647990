import { defineMessages } from 'react-intl';

const recipientMessages = defineMessages({
    title: {
        id: 'recipient.recipientMessages.title',
        description: 'Top-level heading for specifying the names of the recipient and sender of a card',
        defaultMessage: 'Who is the gift card for?'
    },
    pluralTitle: {
        id: 'recipient.recipientMessages.pluralTitle',
        description: 'Top-level heading for specifying the names of the recipient in a bundle',
        defaultMessage: 'Who are these gift cards for?'
    },
    recipientNameLabel: {
        id: 'recipient.recipientMessages.recipientNameLabel',
        description: 'Label text for the card recipient\'s name input',
        defaultMessage: 'Their name'
    },
    recipientNameHelper: {
        id: 'recipient.recipientMessages.recipientNameHelper',
        description: 'Helper text for the card recipient\'s name input',
        defaultMessage: 'This can be a nickname or a first name.'
    },
    senderNameLabel: {
        id: 'recipient.recipientMessages.senderNameLabel',
        description: 'Label text for the card sender\'s name input',
        defaultMessage: 'Your name'
    },
    SenderNameHelper: {
        id: 'recipient.recipientMessages.SenderNameHelper',
        description: 'Helper text for the card sender\'s name input',
        defaultMessage: 'This can be a nickname or a first name.'
    },
    isSelfbuy: {
        id: 'recipient.recipientMessages.isSelfbuy',
        description: 'Text displayed when choosing if the card is for yourself',
        defaultMessage: 'For Me'
    },
    notSelfbuy: {
        id: 'recipient.recipientMessages.notSelfbuy',
        description: 'Text displayed when choosing if the card is for someone else',
        defaultMessage: 'A Friend'
    },
    selfBuyLegend: {
        id: 'recipient.recipientMessages.selfBuyLegend',
        description: 'fieldset legend for chosing who the gift card is for - not visible',
        defaultMessage: 'Is this for me or someone else?'
    },
    submitButtonText: {
        id: 'recipient.recipientMessages.submitButtonText',
        description: 'Text shown on preceding steps on buttons which will move the user to the recipient step',
        defaultMessage: 'Next'
    },
    printInfoTextUnderline: {
        id: 'recipient.recipientMessages.printInfoTextUnderline',
        description: 'choose for me instructions',
        defaultMessage: 'Choose \'for me\''
    },
    printInfoText: {
        id: 'recipient.recipientMessages.printInfoText',
        description: 'download instructions',
        defaultMessage: 'to download and print digital cards'
    }
});

export default recipientMessages;
