import React, { Fragment, useState } from 'react';
import { Map as IMap, OrderedMap as IOrderedMap } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CheckboxToggle } from 'cstar-react-primitives/lib/redux-form/microFields/CheckboxToggle';

import { addressFieldNames } from '../address/addressModule';
import addressMessages from '../address/addressMessages';
import { ADDRESS_CONTEXT, addressFieldPropTypes, defaultFieldProps } from './addressFieldsModule';
import { NEW_ITEM_FORM_NAME } from '../item/newItemForm';
import { changeValue as changeNewItemValue } from '../item/newItemFormModule';
import { PAYMENT_FORM_NAME } from '../payment/paymentConstants';
import { changeValue as changePaymentValue } from '../payment/paymentFormModule';
import { changeItemDisplayValue } from '../item/itemDisplayModule';

import { getReusableShippingAddresses } from '../item/itemSelectors';
import ReusableAddressRadio from './ReusableAddressRadio';
import { CountrySelectField } from './Fields';
import AddressFieldsSwitch from './AddressFieldsSwitch';


export const AddressPicker = ({
    reusableAddresses,
    useSelectedAddress,
    addressContext,
    billingCountries,
    cashbotName,
    countryCode,
    street1,
    street2,
    city,
    state,
    excludedBillingStates,
    postalCode,
    phoneNumber,
    doFieldValidation,
    form,
    intl,
    item,
    shippingCountries,
    showIntlBilling,
    showIntlShipping,
    showUntouchedErrors,
    useAddressAutocomplete,
    setAddressChanged,
    usePreviousAddress
}) => {
    const [showReusableAddresses, setShowReusableAddresses] = useState(false);
    const toggleFieldProps = {
        label: intl.formatMessage(addressMessages.usePreviousAddress),
        cashbotName,
        input: {
            value: showReusableAddresses,
            onChange: setShowReusableAddresses,
            name: 'use-previous-address'
        }
    };

    let addressesToShow = reusableAddresses;
    const isBilling = addressContext === ADDRESS_CONTEXT.BILLING;
    if (isBilling) {
        const statesToExclude = excludedBillingStates.replace(/ /g, '').split(',');
        addressesToShow = reusableAddresses.filter(address => !statesToExclude.includes(address.get('state')));
    }

    const commonFieldProps = {
        addressContext,
        cashbotName,
        doFieldValidation,
        intl,
        showUntouchedErrors
    };
    const countryProps = {
        ...commonFieldProps,
        billingCountries,
        shippingCountries,
        showIntlBilling,
        showIntlShipping
    };
    const localizedFormProps = {
        ...commonFieldProps,
        countryCode,
        item,
        street1,
        street2,
        city,
        state,
        excludedBillingStates,
        postalCode,
        phoneNumber,
        form,
        useAddressAutocomplete,
        setAddressChanged
    };

    if (addressesToShow.size && usePreviousAddress) {
        if (showReusableAddresses) {
            
            return (
                <Fragment>
                    <CheckboxToggle {...toggleFieldProps} />
                    <ReusableAddressRadio addresses={addressesToShow} useSelectedAddress={useSelectedAddress} />
                </Fragment>
            );
        }
        
        return (
            <Fragment>
                <CheckboxToggle {...toggleFieldProps} />
                <CountrySelectField {...countryProps} />
                <AddressFieldsSwitch {...localizedFormProps} />
            </Fragment>
        );
    }
    
    return (
        <Fragment>
            <CountrySelectField {...countryProps} />
            <AddressFieldsSwitch {...localizedFormProps} />
        </Fragment>
    );
};

AddressPicker.defaultProps = {
    ...defaultFieldProps,
    doFocus: false,
    item: IMap(),
    showIntlBilling: false,
    showIntlShipping: false,
    street1: '',
    street2: '',
    city: '',
    state: '',
    postalCode: '',
    phoneNumber: '',
    setAddressChanged: () => {},
    
    reusableAddresses: IOrderedMap(),
    useSelectedAddress: () => {}
};

AddressPicker.propTypes = {
    ...addressFieldPropTypes,
    billingCountries: ImmutablePropTypes.list.isRequired,
    doFocus: PropTypes.bool,
    item: ImmutablePropTypes.map,
    shippingCountries: ImmutablePropTypes.list.isRequired,
    showIntlBilling: PropTypes.bool,
    showIntlShipping: PropTypes.bool,
    setAddressChanged: PropTypes.func,
    reusableAddresses: ImmutablePropTypes.orderedMap,
    useSelectedAddress: PropTypes.func.isRequired
};

export const mapStateToProps = state => ({
    reusableAddresses: getReusableShippingAddresses(state)
});

export const mapDispatchToProps = (dispatch, ownProps) => {
    const { form } = ownProps;
    let setAction;
    switch (form) {
        case NEW_ITEM_FORM_NAME:
            setAction = changeNewItemValue;
            break;
        case PAYMENT_FORM_NAME:
            setAction = changePaymentValue;
            break;
        default:
            
            setAction = (fieldName, value) => changeItemDisplayValue(form, fieldName, value);
    }
    return {
        useSelectedAddress: (address) => {
            dispatch(setAction(addressFieldNames.ADDRESS_STREET1, address.get(addressFieldNames.ADDRESS_STREET1)));
            dispatch(setAction(addressFieldNames.ADDRESS_STREET2, address.get(addressFieldNames.ADDRESS_STREET2)));
            dispatch(setAction(addressFieldNames.ADDRESS_CITY, address.get(addressFieldNames.ADDRESS_CITY)));
            dispatch(setAction(addressFieldNames.ADDRESS_STATE, address.get(addressFieldNames.ADDRESS_STATE)));
            dispatch(setAction(addressFieldNames.ADDRESS_POSTCODE, address.get(addressFieldNames.ADDRESS_POSTCODE)));
            dispatch(setAction(addressFieldNames.ADDRESS_COUNTRY, address.get(addressFieldNames.ADDRESS_COUNTRY)));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressPicker);
