import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { List as IList } from 'immutable';
import { LoadingIndicator } from '../primitive/LoadingIndicator';

import NotFound from '../error/NotFound';
import ErrorBoundary from '../error/ErrorBoundary';

import AsyncFlowStep from './AsyncFlowStep';
import Redirect from './Redirect';
import { getBasePath } from './flow';
import { getStepConfigs, getHomePath } from './flowSelectors';
import { FlowProvider } from './FlowProvider';
import ProgramStep from '../program/ProgramStep';
import RecipientStep from '../recipient/RecipientStep';
import FlowStep from './FlowStep';
import CardTypeStep from '../cardType/CardTypeStep';
import PaymentPlusRedirectHandler from '../paymentJS/RedirectHandler';

import { hasProductDetailsPageEnabled } from '../app/bootstrap';
import ProductDetailWrapper from '../productDetail/ProductDetailWrapper';



export const mapStepConfigToProps = (stepProps, stepConfig) => ({
    component: stepConfig.get('component'),
    flow: stepConfig.get('flow'),

    
    flowProps: stepConfig.get('flowProps', (_, ownProps) => ownProps),
    beforeRender: stepConfig.get('beforeRender', () => {}),
    isReady: stepConfig.get('isReady', () => true),
    canLoad: stepConfig.get('canLoad', () => ({ isAllowedOrPath: true })),
    canSkip: stepConfig.get('canSkip', () => false),
    onExit: stepConfig.get('onExit', () => {}),
    preConditions: stepConfig.get('preConditions', IList()),
    postConditions: stepConfig.get('postConditions', IList())
});


const mapStepConfigsToRoutes = steps => (
    steps.entrySeq().map(([path, stepConfig]) => (
        <Route
            key={path}
            path={`/${getBasePath()}/${path}`}
            render={renderProps => (
                <AsyncFlowStep
                    {...renderProps}
                    path={path}
                    {...mapStepConfigToProps(renderProps, stepConfig)}
                />
            )}
        />
    ))
);



export const flowFactory = reduxStore => () => {
    const currentStepConfigs = getStepConfigs(reduxStore);
    const basePath = getBasePath();
    const homePath = `/${basePath}/${getHomePath(reduxStore)}`;
    const homeRedirect = <Redirect pathname={homePath} />;

    const flow = mapStepConfigsToRoutes(currentStepConfigs);

    const isproductDetailsPageEnabled = hasProductDetailsPageEnabled();

    return (
        <ErrorBoundary>
            <FlowProvider>
                <Suspense fallback={<LoadingIndicator />}>
                    <Switch>
                        <Route key="home" exact path="/" render={() => homeRedirect} />
                        <Route key="store" exact path="/store" render={() => homeRedirect} />
                        {}
                        <Route key="legacy" path={['/store/buy', '/store/buy/*']} render={() => homeRedirect} />
                        <Route
                            key="program"
                            exact
                            path={`/${basePath}/program`}
                            render={renderProps => (
                                <FlowStep {...renderProps} stepName="program" component={ProgramStep} />
                            )}
                        />
                        <Route
                            key="recipient"
                            exact
                            path={`/${basePath}/recipient`}
                            render={renderProps => (
                                <FlowStep {...renderProps} stepName="recipient" component={RecipientStep} />
                            )}
                        />
                        <Route
                            key="type"
                            exact
                            path={`/${basePath}/type`}
                            render={renderProps => (
                                <FlowStep {...renderProps} stepName="type" component={CardTypeStep} />
                            )}
                        />
                        <Route
                            key="redirect-order-handler"
                            exact
                            path={['/storefront/paymentConfirmation', '/storefront/paymentFailed']}
                            render={renderProps => (
                                <PaymentPlusRedirectHandler {...renderProps} stepName="redirection-flow-order" />
                            )}
                        />
                        {flow}
                        <Route
                            key="product_detail"
                            exact
                            path={`/${basePath}/product_detail`}
                            render={renderProps => (isproductDetailsPageEnabled ? (
                                <FlowStep {...renderProps} stepName="product-detail" component={ProductDetailWrapper} />
                            ) : (
                                homeRedirect
                            ))}
                        />
                        <Route component={NotFound} />
                    </Switch>
                </Suspense>
            </FlowProvider>
        </ErrorBoundary>
    );
};
